const skilldata = [
    {
        id: 1,
        title: "MS-SQL",
        grade: 2,
    },
    {
        id: 2,
        title: "IIS",
        grade: 3,
    },
    {
        id: 3,
        title: "MySQL",
        grade: 2,
    },
    {
        id: 4,
        title: "Kubernetes",
        grade: 1,
    },
    {
        id: 5,
        title: "Docker",
        grade: 1,
    },
    {
        id: 6,
        title: "Python",
        grade: 1,
    },
    {
        id: 7,
        title: "Linux",
        grade: 1,
    },
    {
        id: 8,
        title: "MS-Server",
        grade: 2,
    },
    {
        id: 9,
        title: "React",
        grade: 3,
    },
    {
        id: 9,
        title: "React Native",
        grade: 1,
    },
    {
        id: 10,
        title: "Java",
        grade: 1,
    },
    {
        id: 11,
        title: "git",
        grade: 1,
    },
    {
        id: 12,
        title: "Scrum",
        grade: 1,
    },
]

export default skilldata