const maindata = [
    {
        id: 1,
        title: "Netcetera AG",
        date: "10.2021 - heute",
        position: "Application Support Mobile Banking",
        text: "Ticket handling.$newlineAufbereiten der Enwickleraufgaben.$newlineDokumentation interner Abläufe.$newlinePlanung der Auslieferung neuer Applikationsversionen mit Kundenkontakt.",
    },
    {
        id: 2,
        title: "tpc Switzerland AG",
        date: "01.2014 - 05.2020",
        position: "Applikations- & Systemadministrator",
        text: "Konzeptionierung, Installation und Betrieb der Microsoft WebFarm (IIS) mit LoadBalancer.$newlinePlanung, Koordination und Umsetzung der Microsoft SQL Datenbank Lifecycles.$newlineBetreibung & Administration der Microsoft SQL Datenbank Servern in stellvertretender Funktion.$newlineAufbau, Installation und Betrieb der MySQL Datenbank Server.$newlinePlanung, Koordination und Umsetzung von Server Lifecycles von diversen Spezial Applikationen sowie deren Betrieb.$newlineAktive Mitarbeit bei Server Systemwartungen (Installation von Security Updates, Patches und Service Packs).$newlineSupport auf Stufe Second Level und Third Level für spezifische Broadcast Applikationen (Radio/Fernsehen), sowie deren Betrieb.$newlineBetrieb und Administration von Broadcast IT Systemen in Stellvertretender Funktion.",
    },
    {
        id: 3,
        title: "tpc Switzerland AG",
        date: "08.2013 - 01.2014",
        position: "Second-Level-Support",
        text: "Lösung & Priorisierung in Ticketsupport.$newlineÜberwachung der IT-Systemen (WhatsUp Gold).$newlineBenutzer- & Gruppenverwaltung in Microsoft Active Directory.$newlineErstellen von Scripts für wiederholende Support arbeiten.",
    },
]

export default maindata