import { Container, Row, Image } from "react-bootstrap";
import CvMain from "./cv/cv_main/CvMain"
import  {Route, Routes } from "react-router-dom";

function App() {
  document.querySelector('title').textContent = "Leo Meier"
  return (
    <div className="App"> 
      <Container>
        <Row>   
          <header className="App-header">
            <p className="m-0 text-center"> Welcome to my website, sadly you're too early. We are still under construction!</p>
          <Image src="img/uc.png" className="img-fluid mx-auto d-block"/>
        </header>
        </Row>
      </Container> 
    </div>
  );
}

export default App;
