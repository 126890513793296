const studies = [
    {
        id: 1,
        title: "ZHAW-Winterthur",
        date: "08.2018 - 07-2022",
        position: "Bachelorstudium Informatik",
        text: "Bachelor's degree in computer science part-time at the ZHAW Winterthur.",
    },
    {
        id: 2,
        title: "tpc Switzerland AG",
        date: "08.2009 - 08.2013",
        position: "Lehrausbildung zum Informatiker EFZ",
        text: "Apprenticeship in systems engineering with vocational baccalaureate.",
    },
]

export default studies