import {Image, Row, Col} from "react-bootstrap"
import {Linkedin, Telephone, Envelope, PinMap, Calendar2Date} from "react-bootstrap-icons"

function CvPers(props) {
  return (
      <>
        <Image roundedCircle src="img/LMR.jpg" width={"180px"} height={"auto"} style={{verticalAlign: "middle", position: "relative", top: props.imagepadding, marginLeft: "auto", marginRight: "auto", display: "block",  boxShadow: props.shadow}}/>
        <div className="mt-2"style={{backgroundColor: props.color1, boxShadow: props.shadow, borderRadius: props.radius, padding: props.perspadding, WebkitPrintColorAdjust: "exact"}}>
            <h2>
            {props.t("Pers")}
            </h2>
            {props.seperator}            
            <Row>
              <Col>
                <Calendar2Date  size={20} style={{paddingBottom: 3}}/>
                <p style={{display: "inline", paddingLeft: 6}}>30.12.1993</p>
              </Col>
            </Row>  
            <Row>
              <Col>
                <PinMap  size={20} style={{paddingBottom: 3}}/>
                <p style={{display: "inline", paddingLeft: 6}}>Tüchelrosstrasse 15, 8248 Uhwiesen</p>
              </Col>
            </Row>  
            <Row>
              <Col>
                <Envelope size={20} style={{paddingBottom: 3}}/>
                <a style={{color: "black", paddingLeft: 6}} href="mailto: l30meier@gmail.com">l30meier@gmail.com</a>
              </Col>
            </Row>   
            <Row>
              <Col>
                <Telephone size={20} style={{paddingBottom: 3}}/>
                <a style={{color: "black", paddingLeft: 6}} href="tel:+41 78 810 96 18">+41 78 810 96 18</a>
              </Col>
            </Row>  
            <Row>
              <Col>
                <Linkedin size={20} style={{paddingBottom: 3}}/>
                <a style={{color: "black", paddingLeft: 6}} href="https://www.linkedin.com/in/leo-meier-7a8875178/">Leo Meier</a>
              </Col>
            </Row>  
            
        </div>     
      </>
  );
}

export default CvPers;
