const hobbiedata = [
    {
        id: 1,
        title: "Cooking",
        grade: 0,
    },
    {
        id: 2,
        title: "Games",
        grade: 0,
    },
    {
        id: 3,
        title: "Squash",
        grade: 0,
    },
    {
        id: 4,
        title: "Tennis",
        grade: 0,
    },
    {
        id: 5,
        title: "Badminton",
        grade: 0,
    },
    {
        id: 6,
        title: "Listen to music",
        grade: 0,
    },
    {
        id: 7,
        title: "Murder mystery Games",
        grade: 0,
    },
]

export default hobbiedata