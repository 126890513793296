import i18n from "i18next";
import { initReactI18next } from "react-i18next";


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          "CvHeader": "Resume",
          "Pers" : "Personal",
          "Strenghts" : "Strengths",
          "Skill" : "Skills",
          "Hobbies" : "Hobbies",
          "Abstract" : "Carrer Summary",
          "Job" : "Experience",
          "Studies" : "Education"
        }
      }, 
      de: {
        translation: {
            "CvHeader": "Lebenslauf",
            "Pers" : "Persönliches",
            "Strenghts" : "Stärken",
            "Skill" : "Fähigkeiten",
            "Hobbies" : "Hobbies",
            "Abstract" : "Lebenslauf",
            "Job" : "Arbeitserfahrung",
            "Studies" : "Bildung"
        }
      }, 
    },
    lng: "de", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })

  export default i18n;