import React from "react";

function CvContentMain(props){


    const text = props.content.text ?  props.content.text : ""
    const textArray = text.split("$newline")


    return(
        <>
            <h4>
                {props.content.title}
            </h4>
                <h5>
                    {props.content.position}
                </h5>
                <p style={{margin: 0, fontSize: 12, fontWeight: "bold" }}>{props.content.date}</p>
                {props.noList ? <p>{text}</p> : <ul>{textArray.map((element, key) =>{return(<li>{element}</li>)})}</ul> }        
        </>
    )
}

export default CvContentMain