const strenghtdata = [
    {
        title: "Team player",
    },
    {
        title: "Problem solving",
    },
    {
        title: "Costumer-oriented",
    },
    {
        title: "Willing to learn",
    },
    {
        title: "Flexible",
    },
]

export default strenghtdata