import {Row, Col} from "react-bootstrap"

function CvFooter(props) {

  return (
    <div className="mt-2 mb-2 d-print-none">
      <header style={{backgroundColor: props.color1, boxShadow: props.shadow, borderRadius: props.radius, WebkitPrintColorAdjust: "exact"}}>
        <Row>
            <Col>
                <p className="m-0 text-center">@Bootsrap extended by Leo Meier</p>
            </Col>
        </Row>
      </header>
    </div>
  );
}

export default CvFooter;
