import CvHeader from "../components/CvHeader";
import {Container, Col, Row} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CVPers from "./components/CvPers";
import CVCard from "./components/CvCard";
import deskill from "../cv_data/de/de_skill";
import destrengh from "../cv_data/de/de_strenght";
import dehobbiedata from "../cv_data/de/de_hobbie";
import dejobdata from "../cv_data/de/de_job";
import destudiedata from "../cv_data/de/de_studies";
import deabstract from "../cv_data/de/de_abstract";
import enskill from "../cv_data/en/en_skill";
import enstrengh from "../cv_data/en/en_strenght";
import enhobbiedata from "../cv_data/en/en_hobbie";
import enjobdata from "../cv_data/en/en_job";
import enstudiedata from "../cv_data/en/en_studies";
import enabstract from "../cv_data/en/en_abstract";
import "../css/print.css";
import CvFooter from "../components/CvFooter";



function CvMain() {
  //Site changes
  document.querySelector('title').textContent = "CV - Leo Meier"
  document.querySelector("link[rel~='icon']").href= "img/favicon.ico"
  

  const color1 = "#FAD5C8"
  const color2 = "#F5F5F5"
  const shadow = "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px"
  const radius = "5px"
  const imagepadding = "25px"
  const perspadding = "25px 10px 10px"
  const cardpadding = "0px 10px 10px"
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const seperator = <div style={{height: 1, background: "#c1c1c1", width: "100%"}}/>


  return (
    <div className="CV">
      <Container>
        <CvHeader color1={color1} color2={color2} shadow={shadow} radius={radius} t ={t} />
        <Row>
          <Col xs={12} sm={4} md={4} lg={3}>
            <CVPers seperator={seperator} color1={color1} color2={color2} shadow={shadow} radius={radius} t ={t} imagepadding={imagepadding} perspadding={perspadding}/>
            <CVCard seperator={seperator} title={"Strenghts"} color1={color1} color2={color2} shadow={shadow} radius={radius} t ={t} cardpadding={cardpadding} content={i18n.language === "de" ? destrengh : enstrengh }/>
            <CVCard seperator={seperator} title={"Hobbies"} color1={color1} color2={color2} shadow={shadow} radius={radius} t ={t} content={i18n.language === "de" ? dehobbiedata : enhobbiedata }/>
            <CVCard seperator={seperator} title={"Skill"}  color1={color1} color2={color2} shadow={shadow} radius={radius} t ={t} content={i18n.language === "de" ? deskill : enskill} />        
          </Col>
          <Col xs={12} sm={8} md={8} lg={9} >
           <CVCard seperator={seperator} title={"Abstract"} color1={color1} color2={color2} shadow={shadow} radius={radius} noPrint={true} t ={t} cardpadding={cardpadding} content={i18n.language === "de" ? deabstract : enabstract}/>
           <CVCard seperator={seperator} title={"Job"} color1={color1} color2={color2} shadow={shadow} radius={radius} t ={t} cardpadding={cardpadding} content={i18n.language === "de" ? dejobdata : enjobdata}/>
           <CVCard seperator={seperator} title={"Studies"} color1={color1} color2={color2} shadow={shadow} radius={radius} t ={t} cardpadding={cardpadding} content={i18n.language === "de" ? destudiedata : enstudiedata}/>
          </Col>        
        </Row>
        <Row>
          <CvFooter color1={color1} color2={color2} shadow={shadow} radius={radius}/>
        </Row>
      </Container>
    </div>
  );
}

export default CvMain;
