import CvMain from "./cv/cv_main/CvMain"
import App from "./App"
import  { Route, HashRouter, Routes } from "react-router-dom";

function Router() {
  return (
    <>  
      <HashRouter>
        <Routes>
          <Route exact path={'/'} element={<App />}/>
          <Route exact path={'/cv'}  element={<CvMain />}/>      
        </Routes>
      </HashRouter>
    </>
  );
}

export default Router;
