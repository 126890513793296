const maindata = [
    {
        id: 1,
        title: "ZHAW-Winterthur",
        date: "08.2018 - 07-2022",
        position: "Bachelorstudium Informatik",
        text: "Bachelorstudium Informatik Teilzeit an der ZHAW Winterthur",
    },
    {
        id: 2,
        title: "tpc Switzerland AG",
        date: "08.2009 - 08.2013",
        position: "Lehrausbildung zum Informatiker EFZ",
        text: "Lehrausbildung zum Informatiker Systemtechnik mit eidgenössischem Fähigkeitszeugnis und Berufsmatura.",
    },
]

export default maindata