import {HandThumbsUp, Controller, Gear, Book, Briefcase, FilePerson} from "react-bootstrap-icons"
import { Col, Row,} from "react-bootstrap"
import CvBadge from "./CvBadge";
import CVContentMain from "./CvContentMain";


function CvCard(props) {

  const getItem = (title) =>{
    switch(title) {
      case 'Strenghts':
        return <HandThumbsUp size={42} style={{paddingBottom: 15}}/>
      case 'Abstract':
        return <FilePerson size={42} style={{paddingBottom: 15}}/>
      case 'Hobbies':
        return <Controller size={42} style={{paddingBottom: 15}}/>
      case 'Skill':
        return <Gear size={42} style={{paddingBottom: 15}}/>
      case 'Job':
        return <Briefcase className="printbreakAlways" size={42} style={{paddingBottom: 15}}/>
      case 'Studies':
        return <Book className="printbreakAlways" size={42} style={{paddingBottom: 15}}/>
      default:
        console.log("contenttype: " + title + "not available")
    }
  }

  const getContent=(content, title) => {
    switch(title) {
      case 'Strenghts':
        return getlist(content)
      case 'Hobbies':
        return <div className="mt-2">
          {getBadge(content)}
        </div>
      case 'Skill':
        return <div className="mt-2">
          {getBadge(content)}
        </div>
      case 'Abstract':
        return <div className="mt-2">
          {content.map((element, key) => <CVContentMain content={element} seperator={props.seperator} noList={true}/>)}
        </div>
      case 'Job':
        return <div className="mt-2">
          {content.map((element, key) => <CVContentMain content={element} seperator={props.seperator}/>)}
        </div>
      case 'Studies':
        return <div className="mt-2">
          {content.map((element, key) => <CVContentMain content={element} seperator={props.seperator}/>)}
        </div>
      default:
        console.log("contenttype: " + title + "not available")
    }
  }

  const getlist = (content) => {
    return (
      <>
        <ul style={{marginBottom: "0px"}} >
          {content.map( (element, key) => {
            return(
              <li>
                {element.title}
              </li>
            )})}
        </ul>
      </>
    )
  }

  const getBadge = (content) => {
    return(
      <>
        {content.map( (element, id) => {
            return(
              <>
                    <CvBadge element={element} color1={props.color1}></CvBadge>
              </>
            )})}
      </>
    )

  }

  return (
  <>
  <div className={`mt-2 pb-2 pt-2 printbreak ${props.noPrint ? "d-print-none" : ""}`} style={{backgroundColor: props.color2, boxShadow: props.shadow, borderRadius: props.radius, padding: props.cardpadding, WebkitPrintColorAdjust: "exact"}}>
      <Row>
        <Col>
          {getItem(props.title)}
          <h2 style={{display: "inline", paddingLeft: 6}}>
            {props.t(props.title) // use translation props.t("cvHeader")
            }
          </h2>
          {props.seperator}
        </Col>
      </Row>      
      <Row>
        <Col>
          {getContent(props.content, props.title)}
        </Col>
      </Row>
    </div>
  </>
    
  );
}

export default CvCard;
