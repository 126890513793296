const strenghtdata = [
    {
        title: "Teamfähig",
    },
    {
        title: "Problemlösen",
    },
    {
        title: "Kundenorientiert",
    },
    {
        title: "Lernbereit",
    },
    {
        title: "Flexibel",
    },
]

export default strenghtdata