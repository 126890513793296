import React from "react";
import {Badge} from "react-bootstrap"


function CvBadge(props){

    const getVariant = (grade) => {
        switch(grade){
            case 0:
                return 'hobbie'
            case 1:
                return 'grade1'
            case 2:
                return 'grade2'
            case 3:
                return 'grade3'
            default:
                return 'danger'
        }
    }

    return(
        <>
            <style type="text/css">
                        {`
            .bg-grade1 {
                background-color: #FAD5C8;
                color: black;
                -webkit-print-color-adjust: exact; 
            }
            
            .bg-grade2 {
                background-color: #ffbca3;
                color: black;
                -webkit-print-color-adjust: exact; 
            }
            
            .bg-grade3 {
                background-color: #ff9a75;
                color: black;
                -webkit-print-color-adjust: exact; 
            }
            
            .bg-hobbie {
              color: black;
              border: 2px solid #ff9a75;
              -webkit-print-color-adjust: exact; 
            }
            `}
            </style>
                        <h4 style={{ display: "inline", marginLeft: 6}}>
                                <Badge className="mt-2" pill bg={getVariant(props.element.grade)}>
                                    {props.element.title}
                                </Badge>
                        </h4>
        </>
    )
}

export default CvBadge