const maindata = [
    {
        id: 1,
        title: "Netcetera AG",
        date: "10.2021 - heute",
        position: "Application Support Mobile Banking",
        text: "Ticket handling.$newlinePreparation of developer tasks.$newlineDocumentation of internal processes.$newlinePlanning delivery of new application versions with customer contact.",
    },
    {
        id: 2,
        title: "tpc Switzerland AG",
        date: "01.2014 - 05.2020",
        position: "Applikations- & Systemadministrator",
        text: "Design, installation and operation of the Microsoft WebFarm (IIS) with LoadBalancer.$newlinePlanning, coordination and implementation of the Microsoft SQL database lifecycles.$newlineSetup, installation and operation of MySQL database servers.$newlinePlanning, coordination and implementation of server lifecycles of various special applications as well as their operation.$newlineActive cooperation in server operating system maintenance (installation of security updates, patches and service packs).$newlineSupport on second and third level for specific broadcast applications (radio/television), as well as their operation. Operation & administration of broadcast IT systems in a deputy function.",
    },
    {
        id: 3,
        title: "tpc Switzerland AG",
        date: "08.2013 - 01.2014",
        position: "Second-Level-Support",
        text: "Resolution & prioritisation in ticket support.$newlineMonitoring of IT systems (WhatsUp Gold).$newlineUser and Group and management in Microsoft Active Directory.$newlineScripting Repetitive support work.",
    },
]

export default maindata