import { useTranslation } from "react-i18next";
import {Row, Col, Button} from "react-bootstrap"

function CvHeader(props) {
  const { i18n } = useTranslation()

  const changeLanguage = () => {
    if(i18n.language === "de"){
      i18n.changeLanguage("en");
    }else{
      i18n.changeLanguage("de");
    }
    
  }

  return (
    <div className="mt-2">
      <header style={{backgroundColor: props.color1, boxShadow: props.shadow, borderRadius: props.radius, WebkitPrintColorAdjust: "exact"}}>
        <Row>
            <Col>
                <h2 className="m-1">{props.t("CvHeader")}</h2>
            </Col>
            <Col >
                <Button className="d-print-none" size="lg" style={{backgroundColor: props.color2, color: "black", float: "right", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", border: "0px", WebkitPrintColorAdjust: "exact"}} onClick={(e)=>{changeLanguage()}}>{i18n.language}</Button>
            </Col>
        </Row>
      </header>
    </div>
  );
}

export default CvHeader;
